<template>
  <div class="pa-6 ma-4">
    <p class="text-2xl mb-6">CA journalier par site</p>

    <v-expansion-panels class="mb-2" id="expandableFilters">
      <v-expansion-panel>
        <v-expansion-panel-header> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3">
            <v-col cols="6" sm="4" md="3">
              <partner-group-filter store="premium" />
            </v-col>
            <v-col cols="6" sm="4" md="6">
              <partner-view-filter store="premium" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mb-0 pt-2">
      <v-row class="mt-3 mb-0 d-flex">
        <v-col cols="3" class="mb-0 pb-0">
          <!-- <date-filter
                  store="premium"
                  storeGetter="getMonthDate"
                  storeUpdater="updateMonthDate"
                  format="month"
                  :isRange="false"
                /> -->
          <date-range-filter
            @valueChanged="setDates"
            @frequencyChanged="setFrequency"
            periodType="past-and-today"
            format="date"
            :frequencies="['daily', 'weekly', 'monthly']"
            :frequency="this.frequencySelected"
            range="CURRENT_MONTH"
            :onCreatedEmitDates="true"
            :outlined="true"
            :allowNoLimit="false"
            wrapperClass="date-range-filter"
            :showOpenDialogBtn="false"
            class="mb-0 pb-0"
          ></date-range-filter
          ><!--:showBtnList="['CURRENT_MONTH']"-->
        </v-col>
        <v-col cols="3">
          <site-group-filter store="premium" :dense="true" />
        </v-col>
        <v-col cols="4">
          <site-filter store="premium" :dense="true" />
        </v-col>
        <v-col cols="1">
          <v-btn @click="confirmApplyFilters" outlined color="primary">
            APPLIQUER
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="12">
        <ca-and-audience-by-site-separated-chart
          v-if="sites.length > 0 && dateSelected.length === 2"
          :params="applyedFilters"
        ></ca-and-audience-by-site-separated-chart>
        <!--:dates="dateSelected"
          :partners-configs="partnersConfigs"
          :sites="sites"-->
        <div v-else>Veuillez sélectionner un ou plusieurs sites</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";

import PartnerGroupFilter from "@/components/common/filters/PartnerViewGroupFilter";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
import CaAndAudienceBySiteSeparatedChart from "@/views/cards/premium/CaAndAudienceBySiteSeparatedChart";
import { saveRmraView } from "@/utils/rmr-analytics";
import { getDateStr, daysNumberBetween } from "@/utils/dates";

export default {
  name: "CaAndAudienceBySite",
  components: {
    ApplyFilters,
    CaAndAudienceBySiteSeparatedChart,
    DateRangeFilter,
    PartnerGroupFilter,
    PartnerViewFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  data() {
    return {
      // sites: [], // for tests
      dailyWarningTreshold: 180,
      applyedFilters: {},
      componentKey: 0,
      usePrettyNumbers: true,
      loading: false,
      frequencySelected: "daily",
      dateList: [],
      dateSelected: [],
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
  async created() {
    // this.sites = [2, 126]; // uncomment for tests and sites in data and comment sites() in computed
    this.resetFilters();
    let siteGroups = this.$route.query.siteGroups;
    if (siteGroups) {
      siteGroups = siteGroups.split(",");
      await this.$store.dispatch("premium/updateSiteGroups", siteGroups);
    }
  },
  methods: {
    async confirmApplyFilters() {
      console.log("confirmApplyFilters");
      console.log("this.sites", this.sites);

      // check  sites
      if (this.sites.length < 1) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Veuillez sélectionner au moins un site.`,
          color: "warning",
          timeout: 2000,
        });
        return;
      }
      // check frequency and number of days
      if (this.dateSelected.length !== 2) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Veuillez sélectionner une pèriode entre 2 dates.`,
          color: "warning",
          timeout: 2000,
        });
        return;
      }
      const daysNumber = daysNumberBetween(
        this.dateSelected[0],
        this.dateSelected[1]
      );
      console.log("daysNumber", daysNumber);
      console.log("this.frequencySelected", this.frequencySelected);
      if (
        this.frequencySelected == "daily" &&
        daysNumber > this.dailyWarningTreshold
      ) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `La pèriode sélectionnée excède ${this.dailyWarningTreshold} jours alors  
            la périodicité passe automatiquement de "daily" à "weekly" afin d'assurer un affichage correct du graphique.`,
          color: "info",
          timeout: 5000,
        });
        console.warn(
          `in daily ${daysNumber} jours > dailyWarningTreshold ${this.dailyWarningTreshold} then changed to "weekly"`
        );
        this.frequencySelected = "weekly";
        this.applyFilters();
        return;
      }
      // await store.dispatch("app/setDialog", {
      //   show: true,
      //   type: "confirm",
      //   width: 800,
      //   title: "Appliquer",
      //   text: `Vous souhaitez afficher ${daysNumber} jours en pèriodicité "daily".
      //         Cela risque d'être très long.
      //         Il est préférable d'annuler et sélectionner une périodicité "Weekly" ou "monthly".
      //         ou bien un intervalle inférieur à ${this.dailyWarningTreshold} jours.`,
      //   code: null,
      //   callbackFunction: this.applyFilters,
      //   //callbackFunction: () => { this.applyFilters(); },
      // });
      this.applyFilters();
    },
    applyFilters() {
      console.log("applyFilters");
      this.applyedFilters = {
        sites: this.sites,
        dates: this.dateSelected,
        frequency: this.frequencySelected,
        partnersConfigs: this.partnersConfigs,
      };
    },
    resetFilters() {
      // let monthDate = new Intl.DateTimeFormat("fr-FR")
      //   .format(new Date())
      //   .split("/");
      // this.$store.dispatch(
      //   "premium/updateMonthDate",
      //   `${monthDate[2]}-${monthDate[1]}`
      // );
      this.$store.dispatch("premium/updatePartnerViewGroups", [9]);
    },
    getDateStr(date) {
      return getDateStr(date);
    },
    setDates(dates) {
      console.log("CaAndAudienceBySite DateRangeFilter $emit setDates", dates);
      this.dateSelected = dates;
    },
    prettyNbs(x, sep = " ") {
      if (typeof x == "undefined") return x;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    setFrequency(frequencyObj) {
      console.log("setFrequency(frequencyObj)", frequencyObj);
      this.frequencySelected = frequencyObj.name;
    },
  },
  computed: {
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    monthDate() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnersConfigs() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
};
</script>
<style>
.v-input__control {
  padding-bottom: 0 !important;
}
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>
