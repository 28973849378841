<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-scale-transition"
    max-width="950"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="`Date range (${frequencySelectedName})`"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        :class="`${wrapperClass} date-range-text-field`"
      >
        <template v-slot:append-outer>
          <v-btn-toggle v-model="superFastBtnSelected" class="super-fast-btns">
            <v-btn
              v-for="item in superFastBtnList"
              :key="item.key"
              @click="setDatesFast(item.key)"
              color="primary"
            >
              {{ item.name }}
            </v-btn>
            <v-btn v-if="showOpenDialogBtn" color="primary" v-on="on">{{
              showOpenDialogBtnTxt
            }}</v-btn>
          </v-btn-toggle>
        </template>
      </v-text-field>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <!-- <v-toolbar color="primary" dark>Sélectionner une pèriode</v-toolbar> -->
        <v-card-title class="ma-3 justify-center">
          Période sélectionnée: <b class="ml-3">{{ dateRangeText }}</b>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-date-picker
                v-model="datesTmp"
                range
                :type="format"
                v-bind="{
                  ...(dateMin ? { min: dateMin } : {}),
                  ...(dateMax ? { max: dateMax } : {}),
                }"
                locale="FR-fr"
                first-day-of-week="1"
                selected-items-text="Durée selectionnée"
                :disabled="disabled"
                :outlined="outlined"
                show-adjacent-months
                show-week
                @change="onDateChangeAdjustByFrequency"
              ></v-date-picker>
            </v-col>
            <v-col cols="8">
              <div class="mb-6">
                <v-btn-toggle
                  v-model="frequencySelectedId"
                  background-color="primary"
                  class="v-btn-toggle-frequency"
                  mandatory
                >
                  <v-btn
                    v-for="item in frequencyList"
                    :key="item.id"
                    :value="item.id"
                    @click="setFrequency(item.id)"
                    color="primary"
                  >
                    {{ item.name }}
                  </v-btn>
                </v-btn-toggle>
              </div>

              <template
                v-for="([key, item], index) in Object.entries(fastSelectRanges)"
              >
                <div
                  class="mb-3"
                  v-show="[0, 2, 5, 9, 12, 15].includes(index)"
                  :key="'br-' + key"
                ></div>

                <v-btn
                  v-show="allowNoLimit || key != 'ALL'"
                  @click="setFastSelectRanges(key)"
                  :key="'btn-' + key"
                  dense
                  class="my-1 px-4 set-fast-select-range-btn"
                >
                  {{ item.full_name }}
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="dialog.value = false">Annuler</v-btn>
          <v-btn color="primary" @click="confirmDates"> Appliquer </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
/*
// ***** USE: *****
// <template>
//   <div>
//     <date-range-filter
//       @valueChanged="setDates"
//       periodType="past-and-today"
//       format="date" =====> TODO: handle this if "month"
//       :frequencies="['daily', 'weekly', 'monthly']"
//       frequency="daily"
//       :range="30"
//       :initialValue="['2024-10-01', '2024-10-03']"
//       :onCreatedEmitDates="true"
//       :showBtnList="['YESTERDAY', 'LAST_WEEK', 'LAST_MONTH']"
//       :outlined="true"
//       :allowNoLimit="false"
//       :showOpenDialogBtn="true"
//       wrapperClass="date-range-filter"
//       @frequencyChanged="alert('do whant you want about that')"
//     ></date-range-filter>
//   </div>
// </template>
// <style>
//   .date-range-filter {
//     max-width: 500px;
//   }
// </style>
// <script>
//   import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
//   export default {
//     name: "MyViewName",
//     components: {
//         DateRangeFilter,
//     },
//     methods:{
//       setDates(dates){
//         console.log("setDates", dates)
//       }
//     }
//   }
*/
import { mdiCalendar } from "@mdi/js";
import isEqual from "lodash/isEqual";
import {
  getDateStr,
  getMonthDateStr,
  ONE_DAY_MICROTIME,
  frequencyList,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
} from "@/utils/dates";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);
const TOMORROW = new Date(TODAY.getTime() + ONE_DAY_MICROTIME);

const allFrequencyNames = frequencyList.map((item) => item.name);

const CURRENT_YEAR = new Date().getFullYear();
const PREVIOUS_YEAR = CURRENT_YEAR - 1;

const CURRENT_MONTH_START = new Date(TODAY.getFullYear(), TODAY.getMonth(), 1);
const PREVIOUS_MONTH_START = new Date(
  TODAY.getFullYear(),
  TODAY.getMonth() - 1,
  1
);
const PREVIOUS_MONTH_END = new Date(TODAY.getFullYear(), TODAY.getMonth(), 0); // Last day of previous month
const PREV_AND_CURR_MONTH_START = new Date(
  TODAY.getFullYear(),
  TODAY.getMonth() - 1,
  1
);

const FAST_SELECT_RANGES = {
  // 0
  TODAY: {
    name: "Aujourd'hui",
    full_name: "Aujourd'hui",
    get_dates: () => [TODAY, TODAY],
  },
  YESTERDAY: {
    name: "Hier",
    full_name: "Hier",
    get_dates: () => {
      const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);
      return [YESTERDAY, YESTERDAY];
    },
  },
  LAST_WEEK: {
    name: "7 jours",
    full_name: "7 derniers jours",
    get_dates: () => {
      const LAST_WEEK_START = new Date(TODAY.getTime() - 7 * ONE_DAY_MICROTIME);
      return [LAST_WEEK_START, TODAY];
    },
  },
  LAST_2_WEEKS: {
    name: "14 jours",
    full_name: "14 derniers jours",
    get_dates: () => {
      const LAST_2_WEEKS_START = new Date(
        TODAY.getTime() - 14 * ONE_DAY_MICROTIME
      );
      return [LAST_2_WEEKS_START, TODAY];
    },
  },
  // 4
  LAST_MONTH: {
    name: "1 mois",
    full_name: "30 derniers jours",
    get_dates: () => {
      const LAST_MONTH = new Date();
      LAST_MONTH.setMonth(TODAY.getMonth() - 1);
      return [LAST_MONTH, TODAY];
    },
  },
  LAST_2_MONTHS: {
    name: "2 mois",
    full_name: "2 derniers mois",
    get_dates: () => {
      const LAST_2_MONTHS = new Date();
      LAST_2_MONTHS.setMonth(TODAY.getMonth() - 2);
      return [LAST_2_MONTHS, TODAY];
    },
  },
  LAST_3_MONTHS: {
    name: "3 mois",
    full_name: "3 derniers mois",
    get_dates: () => {
      const LAST_3_MONTHS = new Date();
      LAST_3_MONTHS.setMonth(TODAY.getMonth() - 3);
      return [LAST_3_MONTHS, TODAY];
    },
  },
  LAST_6_MONTHS: {
    name: "6 mois",
    full_name: "6 derniers mois",
    get_dates: () => {
      const LAST_6_MONTHS = new Date();
      LAST_6_MONTHS.setMonth(TODAY.getMonth() - 6);
      return [LAST_6_MONTHS, TODAY];
    },
  },
  LAST_12_MONTHS: {
    name: "12 mois",
    full_name: "12 derniers mois",
    get_dates: () => {
      const LAST_12_MONTHS = new Date();
      LAST_12_MONTHS.setMonth(TODAY.getMonth() - 12);
      return [LAST_12_MONTHS, TODAY];
    },
  },
  //9
  PREVIOUS_MONTH: {
    name: "Mois dernier",
    full_name: "Mois précédent",
    get_dates: () => [PREVIOUS_MONTH_START, PREVIOUS_MONTH_END],
  },
  CURRENT_MONTH: {
    name: "Ce mois",
    full_name: "Mois en cours",
    get_dates: () => [CURRENT_MONTH_START, TODAY],
  },
  PREVIOUS_AND_CURRENT_MONTH: {
    name: "Mois précédent et en cours",
    full_name: "Mois précédent et actuel",
    get_dates: () => [PREV_AND_CURR_MONTH_START, TODAY],
  },
  //12
  PREVIOUS_YEAR: {
    name: PREVIOUS_YEAR,
    full_name: `Année ${PREVIOUS_YEAR}`,
    get_dates: () => {
      const PREVIOUS_YEAR_START = new Date(TODAY.getFullYear() - 1, 0, 1);
      const PREVIOUS_YEAR_END = new Date(TODAY.getFullYear() - 1, 11, 31);
      return [PREVIOUS_YEAR_START, PREVIOUS_YEAR_END];
    },
  },
  CURRENT_YEAR: {
    name: CURRENT_YEAR,
    full_name: `Année ${CURRENT_YEAR}`,
    get_dates: () => {
      const CURRENT_YEAR_START = new Date(TODAY.getFullYear(), 0, 1);
      return [CURRENT_YEAR_START, TODAY];
    },
  },
  PREVIOUS_AND_CURRENT_YEAR: {
    name: `${PREVIOUS_YEAR} - ${CURRENT_YEAR}`,
    full_name: `Années ${PREVIOUS_YEAR} et ${CURRENT_YEAR}`,
    get_dates: () => {
      const PREV_YEAR_START = new Date(TODAY.getFullYear() - 1, 0, 1);
      return [PREV_YEAR_START, TODAY];
    },
  },
  //15
  ALL: {
    name: "Sans limite",
    full_name: "Pas de limite",
    get_dates: () => [],
  },
};

export default {
  name: "DateRangeFilter",
  props: {
    periodType: {
      type: String,
      default: "",
      validator(value) {
        return ["past-and-today", "past-only", "future-only", ""].includes(
          value
        );
      },
    },
    initialValue: {
      type: Array | null,
      default: null,
      validator(value) {
        // console.log("DateRangeFilter initialValue", value, Array.isArray(value) && [0, 2].includes(value.length));
        return (Array.isArray(value) && [0, 2].includes(value.length)) || null;
      },
    },
    showBtnList: {
      type: Array,
      default() {
        return [];
      },
    },
    allowNoLimit: {
      type: Boolean,
      default: true,
    },
    onCreatedEmitDates: {
      type: Boolean,
      default: false,
    },
    showOpenDialogBtn: {
      type: Boolean | String,
      default: false,
    },
    range: {
      type: Number | String,
      default: 14,
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "date",
      validator(value) {
        return ["date", "month"].includes(value); // todo: month
      },
    },
    frequencies: {
      type: Array,
      default() {
        return allFrequencyNames;
      },
      validator(frequencyNames) {
        // console.log("prop frequencyNames", frequencyNames);
        // console.log("prop allFrequencyNames", allFrequencyNames);
        for (let frequencyName of frequencyNames) {
          if (!allFrequencyNames.includes(frequencyName)) return false;
        }
        return true;
      },
    },
    frequency: {
      type: String,
      default: "daily",
      validator(frequencyName) {
        // console.log("prop frequencyName", frequencyName);
        // console.log("prop allFrequencyNames", allFrequencyNames);
        return (
          allFrequencyNames.includes(frequencyName) || frequencyName === null
        );
      },
    },
    wrapperClass: {
      type: String,
      default: "date-range-filter",
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return [
          "admin",
          "audience",
          "c2c",
          "marmiton",
          "premium",
          "regie",
          "gd",
        ].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getDates",
    },
    storeUpdater: {
      type: String,
      default: "updateDates",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    frequencyList: null,
    frequencyNames: allFrequencyNames,
    frequencySelectedId: null,
    frequencySelectedName: null,
    superFastBtnSelected: null,
    dialog: false,
    dates: [],
    datesTmp: [],
    icons: {
      mdiCalendar,
    },
    superFastBtnList: [],
    fastSelectRanges: FAST_SELECT_RANGES,
  }),
  created() {
    // console.log("DateRangeFilter created");
    // this.frequencySelectedIndex = frequencyList.findIndex(item => item.name === this.frequency)
    // this.frequencySelectedId = frequencyList[this.frequencySelectedIndex].id;
    if (!this.frequencies.includes(this.frequency)) {
      alert(
        "DateRangePicker error: Selected frequency prop should be in frequencies prop."
      );
      return;
    }
    this.frequencyList = [];
    for (let item of frequencyList) {
      if (this.frequencies.includes(item.name)) this.frequencyList.push(item);
    }
    // console.log("-- this.frequencyList", this.frequencyList);
    // console.log("-- this.frequency", this.frequency);
    const frequencyItem = this.frequencyList.find(
      (item) => item.name === this.frequency
    );
    // console.log("-- frequencyItem", frequencyItem);
    this.frequencySelectedId = frequencyItem.id;
    this.frequencySelectedName = frequencyItem.name;
    // console.log("-- this.frequencySelectedName", this.frequencySelectedName);
    this.initRangeDates();
    this.initSuperFastBtnList();
    if (this.onCreatedEmitDates) {
      this.confirmDates();
    }
  },
  methods: {
    onDateChangeAdjustByFrequency() {
      // console.log("onDateChangeAdjustByFrequency");
      // console.log("-- this.datesTmp", this.datesTmp);
      //this.datesTmp = ["2024-09-10", "2024-09-11"]
      if (this.datesTmp.length != 2) return;
      switch (this.frequencySelectedName) {
        case "daily":
          // console.log("-- daily");
          break;
        case "weekly":
          // console.log("-- weekly");
          // Adjust the first date to the start of its week
          const startOfWeek = getStartOfWeek(this.datesTmp[0]);
          // Adjust the second date to the end of its week
          const endOfWeek = getEndOfWeek(this.datesTmp[1]);
          // Update datesTmp with new values
          this.datesTmp = [startOfWeek, endOfWeek];
          // console.log(`Updated datesTmp for weekly: [${this.datesTmp[0]}, ${this.datesTmp[1]}]`);
          break;
        case "monthly":
          // console.log("-- monthly");
          // Adjust the first date to the first day of its month
          const startOfMonth = getStartOfMonth(this.datesTmp[0]);
          // Adjust the second date to the last day of its month
          const endOfMonth = getEndOfMonth(this.datesTmp[1]);
          // Update datesTmp with new values
          this.datesTmp = [startOfMonth, endOfMonth];
          // console.log(`Updated datesTmp for monthly: [${this.datesTmp[0]}, ${this.datesTmp[1]}]`);
          break;
      }
    },
    setFrequency(id) {
      // console.log("setFrequency", id);
      const frequencyItem = frequencyList.find((item) => item.id === id);
      this.frequencySelectedId = frequencyItem.id;
      this.frequencySelectedName = frequencyItem.name;
      this.$emit("frequencyChanged", frequencyItem);
      this.onDateChangeAdjustByFrequency();
    },
    initSuperFastBtnList() {
      // this.superFastBtnList = this.fastSelectRanges.filter((x) =>
      //   this.showBtnList.includes(x.id)
      // );
      // this.superFastBtnList = Object.entries(this.fastSelectRanges)
      //   .filter(([key]) => this.showBtnList.includes(key))
      //   .map(([key, value]) => value);
      this.superFastBtnList = this.showBtnList.map((key) => ({
        key, // Use the key from the selected array
        ...this.fastSelectRanges[key], // Spread the properties of the corresponding entry in fastSelectRanges
      }));
      // console.log("initSuperFastBtnList");
      // console.log("-- this.superFastBtnList", this.superFastBtnList);
    },
    setDatesFast(fastSelectRangesId) {
      this.frequencySelectedIndex = 0;
      this.setFastSelectRanges(fastSelectRangesId);
      this.confirmDates();
    },
    confirmDates() {
      if (new Date(this.datesTmp[0]) > new Date(this.datesTmp[1])) {
        this.datesTmp = [this.datesTmp[1], this.datesTmp[0]];
      }
      this.dates = this.datesTmp;
      if (this.useStore === false) {
        if (this.datesTmp !== this.initialValue) {
          this.$emit("valueChanged", this.dates);
        }
      } else {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, this.dates);
      }
      this.dialog = false;
    },
    setFastSelectRanges(fastSelectRangesId) {
      // console.log("setFastSelectRanges fastSelectRangesId", fastSelectRangesId);
      let datesTmp = [];
      if (!fastSelectRangesId in FAST_SELECT_RANGES) {
        alert("Une erreur est survenue");
        return;
      }
      datesTmp = this.fastSelectRanges[fastSelectRangesId].get_dates();
      // console.log("-- datesTmp", datesTmp);
      this.datesTmp =
        datesTmp.length == 2
          ? [this.getDateStr(datesTmp[0]), this.getDateStr(datesTmp[1])]
          : [];
      // console.log("-- this.datesTmp", this.datesTmp);
      this.onDateChangeAdjustByFrequency();
    },
    initRangeDates() {
      // console.log(">>> DateRangeFilter initRangeDates");
      // console.log("-- this.storedDates", this.storedDates);
      // console.log("-- this.storedDates?.length", this.storedDates?.length);

      if (this.initialValue !== null) {
        this.dates = this.initialValue;
        this.datesTmp = this.initialValue;
        // console.log("-- 000");
      } else if (this.range in this.fastSelectRanges) {
        let [startDate, endDate] =
          this.fastSelectRanges[this.range].get_dates();
        this.datesTmp = [this.getDateStr(startDate), this.getDateStr(endDate)];
      } else if (
        this.storedDates?.length !== 2 ||
        this.storedDates === undefined ||
        Object.values(this.storedDates).some((x) => x === undefined)
      ) {
        // console.log("-- AAAA");
        if (this.periodType === "past-and-today") {
          if (this.dateMax !== "") {
            // console.log("---- 111");
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            // console.log("---- 222");
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        } else if (this.periodType === "past-only") {
          if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            const endDate = YESTERDAY;
            const startDate = new Date(
              YESTERDAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        } else if (this.periodType === "future-only") {
          if (this.dateMin !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(
              startDate.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            const startDate = TOMORROW;
            const endDate = new Date(
              TOMORROW.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        } else {
          if (this.dateMin !== "" && this.dateMax !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(this.dateMax);
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else if (this.dateMin !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(
              startDate.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        }
      } else if (!isEqual(this.datesTmp, this.storedDates)) {
        // console.log("-- BBBB");
        this.datesTmp = this.storedDates;
      }
      // console.log("<<< DateRangeFilter initRangeDates END");
    },
    getDateStr(date) {
      if (this.format === "month") {
        return getMonthDateStr(date);
      }

      return getDateStr(date);
    },
  },
  computed: {
    showOpenDialogBtnTxt() {
      return typeof this.showOpenDialogBtn === "boolean" &&
        this.showOpenDialogBtn === true
        ? "+"
        : this.showOpenDialogBtn;
    },
    dateRangeText() {
      if (this.datesTmp.length === 0) {
        return "Pas de limite";
      }
      return this.datesTmp.join(" => ");
    },
    useStore() {
      return this.store !== undefined;
    },
    storedDates() {
      return this.useStore
        ? this.$store.getters[`${this.store}/${this.storeGetter}`]
        : this.initialValue;
    },
    dateMin() {
      if (this.min !== "") {
        return this.min;
      }
      if (this.periodType === "future-only") {
        return this.getDateStr(TOMORROW);
      }
      return "";
    },
    dateMax() {
      if (this.max !== "") {
        return this.max;
      }
      if (this.periodType === "past-only") {
        return this.getDateStr(YESTERDAY);
      }
      return "";
    },
  },
  watch: {
    storedDates() {
      // ici, comme j'ai un souci quand je set dates à [], ça me remet l'initialValue alors que je ne veux pas
      //  this.initRangeDates();
    },
    initialValue: {
      handler(newValue, oldValue) {
        // console.log("watch initialValue oldValue:", oldValue, " => newValue:", newValue);
        if (newValue !== oldValue) {
          this.dates = this.initialValue;
          this.datesTmp = this.initialValue;
        }
      },
      frequencySelectedId: {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.$emit("watch frequencySelectedId newValue", newValue);
            this.setFrequency(newValue);
            // this.onDateChangeAdjustByFrequency();
          }
        },
      },
      // this make infinit loop
      // datesTmp: {
      //   handler(newValue, oldValue) {
      //     if (newValue.length === 2)
      //     if (newValue !== oldValue) {
      //       this.datesTmp = ["2024-09-10", "2024-09-11"]
      //     }
      //   },
      // },
    },
  },
};
</script>
<style>
.date-range-text-field .v-input__append-outer {
  margin-top: 0px !important;
  margin-left: -5px;
  padding: 0;
}
.date-range-text-field .super-fast-btns {
  padding: 0;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
.date-range-text-field .super-fast-btns button.v-btn {
  height: 40px !important;
}
.v-btn-toggle-frequency {
  width: 100%;
  display: flex;
}
.v-btn-toggle-frequency button {
  /*width: 33%;*/
  flex: 1;
}
.v-btn.v-size--default.set-fast-select-range-btn {
  font-size: 0.8rem;
}
</style>
